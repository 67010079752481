<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="st-auth d-flex flex-column flex-lg-row flex-column-fluid bg-white"
      id="st_auth"
    >
      <div
        class="st-auth-aside d-flex flex-column flex-row-auto"
        :style="{ backgroundImage: `url(${backgroundImage})` }"
      >
        <div class="d-flex flex-column-auto flex-column st-auth-aside-logo">
          <a href="#" class="text-center mt-10 mt-lg-0 mb-10">
            <img :src="logoImage" class="max-h-70px" alt="" />
          </a>
        </div>
      </div>
      <div
        class="st-auth-content application-status-container flex-row-fluid d-flex flex-column position-relative overflow-hidden p-7 mx-auto"
      >
        <b-container v-if="application">
          <div class="pr-2" v-if="application.success">
            <b-row class="mb-2">
              <b-col sm="6" class="text-left">
                <strong>{{ $t("PUBLIC_APPLICATION.STATUS") }}</strong>
              </b-col>
              <b-col sm="6" class="text-left">
                <b-badge :variant="getStatus(application.status).type">{{
                  getStatus(application.status).text
                }}</b-badge>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="6" class="text-left">
                <strong>{{ $t("PUBLIC_APPLICATION.APPLICATION_NAME") }}</strong>
              </b-col>
              <b-col sm="6" class="text-left">
                {{ application.application_name }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="6" class="text-left">
                <strong>{{
                  $t("PUBLIC_APPLICATION.IDENTIFICATION_NUMBER")
                }}</strong>
              </b-col>
              <b-col sm="6" class="text-left">
                {{ application.identification_number }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="6" class="text-left">
                <strong>{{ $t("PUBLIC_APPLICATION.DATE") }}</strong>
              </b-col>
              <b-col sm="6" class="text-left">
                {{ application.date }}
              </b-col>
            </b-row>
            <b-row v-if="application.function" class="mb-2">
              <b-col sm="6" class="text-left">
                <strong>{{ $t("PUBLIC_APPLICATION.FUNCTION") }}</strong>
              </b-col>
              <b-col sm="6" class="text-left">
                {{ application.function }}
              </b-col>
            </b-row>
            <div class="mt-8">
              {{ $t("PUBLIC_APPLICATION.INFO") }}
            </div>
            <b-row v-if="application.status === 'closed'" class="mt-8">
              <b-col>
                <b-form-group
                  v-if="phoneStep"
                  :label="$t('PUBLIC_APPLICATION.PHONE_LABEL')"
                >
                  <b-input-group>
                    <b-form-input
                      v-model="phoneNumber"
                      type="tel"
                      :state="phoneState"
                      :placeholder="$t('PUBLIC_APPLICATION.PHONE_PLACEHOLDER')"
                      :aria-describedby="'phone-number-help'"
                      @input="validatePhoneNumber"
                    />
                    <b-input-group-append>
                      <b-button
                        variant="primary"
                        @click="sendSms"
                        :disabled="!isValidPhoneNumber"
                        >{{ $t("PUBLIC_APPLICATION.SEND_SMS") }}</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                  <b-form-text
                    id="phone-number-help"
                    v-if="phoneNumber && !isValidPhoneNumber"
                    class="text-danger"
                  >
                    {{ $t("PUBLIC_APPLICATION.PHONE_ERROR") }}
                  </b-form-text>
                </b-form-group>
                <b-form-group v-else :label="$t('PUBLIC_APPLICATION.SMS_LABEL')">
                  <b-input-group>
                    <b-form-input
                      v-model="smsCode"
                      type="text"
                      :state="smsCodeState"
                      :placeholder="$t('PUBLIC_APPLICATION.SMS_PLACEHOLDER')"
                      :aria-describedby="'sms-code-help'"
                      @input="validateSmsCode"
                      maxlength="6"
                    />
                    <b-input-group-append>
                      <b-button
                        variant="primary"
                        @click="sendSms"
                        :disabled="!isValidSmsCode"
                        >{{ $t("PUBLIC_APPLICATION.VALIDATE_CODE") }}</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                  <b-form-text
                    id="sms-code-help"
                    v-if="smsCode && !isValidSmsCode"
                    class="text-danger"
                  >
                    {{ $t("PUBLIC_APPLICATION.CODE_ERROR") }}
                  </b-form-text>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div v-else>
            <div v-if="application.withError">
              {{ $t("PUBLIC_APPLICATION.TECHNICAL_ERROR") }}
            </div>
            <div v-else>{{ $t("PUBLIC_APPLICATION.NOT_FOUND") }}</div>
          </div>
        </b-container>
        <b-container v-else>
          <div class="d-flex justify-content-center">
            <b-spinner variant="primary"></b-spinner>
          </div>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { ApplicationModel } from "@/modules/applications/models/application-model";
import { BBadge, BSpinner } from "bootstrap-vue";

const { statuses } = ApplicationModel;

export default {
  name: "QRCodeStatus",
  data() {
    return {
      backgroundImage: `${process.env.BASE_URL}media/st-login-bg.jpg`,
      logoImage: `${process.env.BASE_URL}media/st-logo.svg`,
      phoneNumber: "",
      smsCode: "",
      isValidPhoneNumber: null,
      isValidSmsCode: null,
      phoneStep: true,
    };
  },
  created() {
    this.getApplication(this.$route.params.id);
  },
  components: {
    BBadge,
    BSpinner,
  },
  methods: {
    ...mapActions({
      getApplication: "application-status/getApplicationStatus",
    }),
    getStatus(status) {
      return statuses.find((s) => s.value === status);
    },
    validatePhoneNumber() {
      const phonePattern = /^07[0-9]{8}$/;
      this.isValidPhoneNumber = phonePattern.test(this.phoneNumber);
    },
    validateSmsCode() {
      const smsPattern = /^.{6}$/;
      this.isValidSmsCode = smsPattern.test(this.smsCode);
    },
    sendSms() {
      this.phoneStep = false;
    },
  },
  computed: {
    ...mapGetters({
      application: "application-status/application",
    }),
    phoneState() {
      return this.isValidPhoneNumber === null ? null : this.isValidPhoneNumber;
    },
    smsCodeState() {
      return this.isValidSmsCode === null ? null : this.isValidSmsCode;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/pages/auth/auth-layout.scss";
.application-status-container {
  font-size: 16px;
  margin-top: 32px;
  @include media-breakpoint-up(lg) {
    justify-content: center;
    margin-top: 0px;
  }
}
.text-danger {
  color: red !important;
}
.b-input-group {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}
</style>
